@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply h-12 border rounded center text-[12px] hover:opacity-90;
  }
}

@layer utilities {
  .center {
    @apply flex items-center justify-center;
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input {
  outline: none;
}

.btn[disabled] {
  opacity: 0.5;
}

.image-shadow-for-container {
  padding: 10px;
}

.image-shadow-for-container span {
  overflow: visible !important;
}

.image-shadow-for-container img {
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.unreadNotification:hover {
  background: linear-gradient(0deg, rgba(211, 38, 78, 0.1), rgba(211, 38, 78, 0.1)), #FFFFFF !important;
}

.readNotification:hover {
  background-color: #F8F8F8 !important;
}

@media (max-height: 775px) {
  #notification-drawer-container {
    max-height: 500px !important;
  }

  .notification-placeholder {
    height: 500px !important;
  } 

  .notification-placeholder > div {
    margin-bottom: 8% !important;
  }
}

.webkit-transform3d {
  -webkit-transform: translate3d(0,0,0);
}

/* Always visible scrollbar */
.always-visible-scrollbar::-webkit-scrollbar {
  height: 8px; /* Adjust for horizontal scrollbar */
  width: 8px;  /* Adjust for vertical scrollbar */
}

.always-visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: #757575; /* Gray color */
  border-radius: 4px;
}

.always-visible-scrollbar::-webkit-scrollbar-track {
  background-color: #E0E0E0; /* Light gray background */
}


